@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    @include flexStyles(flex, column, center, center);
    padding: 0.5vw;
    max-height: 85vh;
    min-height: 85vh;
}

.body {
    flex: 1;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 5px;
    @include flexStyles(flex, column, normal, normal);
    background-color: $light_bg;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    box-shadow: -1px 0px 5px -1px $main_color;
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}

.formContainer {
    flex: 1;
    width: 100%;
    @include flexStyles(flex, column, normal, normal);
    gap: 20px;
    padding: 10px;
}

.form {
    min-width: 30%;
    width: fit-content;
    @include flexStyles(flex, column, normal, normal);
    gap: 15px;
}

.inputRow {
    @include flexStyles(flex, row, center, space-between);
    gap: 30px;
    flex: 1;
}

.inputContainer {
    @include flexStyles(flex, column, flex-end !important, normal);
}

.inputBox {
    @include flexStyles(flex, row, flex-end !important, normal);
    width: 280px;
    max-width: 280px;
    background-color: $formInput;
    padding: 8px;
}

.input {
    width: 280px;
    max-width: 280px;
    background-color: $formInput;
    font-size: 16px;
    line-height: 18px;
    padding: 8px;
}

.formInputLabel {
    @include textStyles("Montserrat arm", 18px, 600, normal, $main_color);
}

.itemsBox {
    flex: 1;
    @include flexStyles(flex, column, normal, normal);
    gap: 15px;
    padding: 10px;
    margin-right: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.formItemInputBox {
    width: 100%;
    background-color: $formInput;
}

.formItemInputNumBox {
    background-color: $formInput;
    padding: 16px 5px;
}


.formItemInput {
    width: 100%;
    background-color: $formInput;

}

.formItemBox {
    background-color: $formInput;
    border-radius: 10px;

}

.formItemTextBox {
    width: 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 8px;
    @include flexStyles(flex, row, center, center);
    text-wrap: wrap;
    padding: 16px;
    overflow: hidden;
    background-color: $formInput;
    border-radius: 10px;
    border: 1px solid #d0d5dd
}

.formItemText {
    @include textStyles("Montserrat arm", 14px, 400, 15px, $text_dark);
    outline: none;
    height: 15px;
    border: none;
    text-align: center;
    flex: 0.9;
}

.buttonRow {
    @include flexStyles(flex, row, center, space-between);
    padding: 10px;
    border-top: 1px solid $dark_grey;
    background-color: $light_bg;
    position: fixed;
    bottom: 0;
    left: 1.2vw;
    right: 1.2vw;
    box-shadow: -5px 0 5px -5px $main_color;
}

.buttons {
    width: 400px;
    @include flexStyles(flex, row, center, normal);
    gap: 20px;
}

.deleteIcon {
    width: 40px;
    height: 25px;
}

.button {
    border-radius: 10px !important;
    width: fit-content;
}

.deleteButton {
    border-radius: 10px !important;
    width: fit-content;
    background-color: $errorColor
}

.totalBox {
    @include flexStyles(flex, row, center, normal);
    gap: 20px;
    margin-right: 150px
}

.totalText {
    @include textStyles("Montserrat arm", 20px, 500, 15px, $buttun_darkBlue);
}

.totalCount {
    @include textStyles("Montserrat arm", 20px, 500, 15px, $main_color);
}

.totalAmmount {
    @include textStyles("Montserrat arm", 14px, 500, 15px, $main_color);
}


@media screen and (max-width:1640px) {
    .salesPartner {
        max-width: 50vw;
    }

    .body {
        padding: 5px;
    }

    .container {
        @include flexStyles(flex, column, center, center);
        padding: 0.5vw;
        max-height: 84vh;
        min-height: 84vh;
    }
}


@media screen and (max-width:1500px) {
    .body {
        margin-top: 20px;
    }
}

@media screen and (max-width:1370px) {
    .salesPartner {
        max-width: 40vw;
    }
}

@media screen and (max-width:1130px) {
    .salesPartner {
        max-width: 35vw;
    }
}

@media screen and (max-width:1040px) {
    .salesPartner {
        max-width: 30vw;
    }
}

@media screen and (max-width:960px) {
    .salesPartner {
        max-width: 25vw;
    }

    .totalBox {
        flex-direction: column;
        gap: 10px;
        margin-right: 10px
    }
}

@media screen and (max-width:800px) {
    .body{
        border-bottom-left-radius: 15px;
    }
    .formInputLabel {
        font-size: 12px !important;
    }

    .formItemTextBox {
        padding: 12px;
    }

    .formItemText {
        font-size: 12px;
    }

    .formItemBigText {
        font-size: 10px !important;
    }

    .itemsBox {
        padding: 0px;
    }

    .formItemInputNumBox {
        padding: 12px;
    }

    .longTitle {
        font-size: 12px;
        line-height: 16px;
    }

    .totalBox {
        flex-direction: column;
        gap: 5px;
        margin-right: 10px
    }

    .totalText {
        font-size: 14px
    }

    .totalCount {
        font-size: 14px;
        line-height: 10px;
    }

    .totalAmmount {
        font-size: 12px;
    }
    .buttonRow {
        left: 0.6vw;
        right: 0.6vw;
    }

    .buttons {
        gap: 10px;
    }

    .inputBox {
        width: 180px;
        padding: 10px;
    }

    .input {
        width: 180px;
        max-width: 200px;
        background-color: $formInput;
        font-size: 12px;
        line-height: 18px;
        padding: 0px;
    }
    .salesPartner{
        font-size: 10px !important;
        line-height: 12px;
        height: fit-content;
        min-width: fit-content;
    }

}