@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    flex: 1;
    @include flexStyles(flex, column, center, center);
    padding: 15px 10px;
}

.emptyBody {
    flex: 1;
    width: 100%;
    @include flexStyles(flex, column, center, center);
    background-color: $light_bg;
    padding: 20px 0;
    border-radius: 15px;
    box-shadow: -1px 0px 5px -1px $main_color;
}

.fullBody {
    flex: 1;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 10px;
    @include flexStyles(flex, column, normal, normal);
    border-radius: 15px;
    border-bottom-left-radius: 0;
    box-shadow: -1px 0px 5px -1px $main_color;
    overflow: scroll;
    position: relative;
    background-color: $light_bg;
}

.top {
    width: 100%;
    padding-bottom: 15px;
    @include flexStyles(flex, row, flex-end, space-between);
}

.topInputs {
    @include flexStyles(flex, row, normal, normal);
    gap: 20px
}

.inputBox {
    background-color: $formInput;
    font-size: 16px;
    line-height: 18px;
    padding: 7.5px;
    align-items: flex-end
}

.topSelectors {
    @include flexStyles(flex, row, normal, normal);
    gap: 15px;
}

.button {
    border-radius: 10px;
    width: 200px;
    height: 50px;
}

@media screen and (max-width:800px) {
    .top {
        margin-top: 15px;
    }
    .topInputs {
        gap: 10px
    }
    .button {
        width: fit-content;
        height: fit-content;
    }

    .buttoTitle {
        font-size: 14px;
        line-height: 14px;
    }
    .emptyBody {
       padding:50px 0px;
    }
    .inputBox {
        background-color: $formInput;
        font-size: 12px;
        line-height: 12px;
        padding: 6px;
        align-items: flex-end;
    }
}