@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    width: 100%;
    min-height: 100vh;
    @include flexStyles(flex,column,normal,normal);
    background-color: $page_background;;
}

.page{
    margin-top: 55px;
    padding: 1vw;
    flex: 1;
    @include flexStyles(flex,column,normal,normal)
}

@media screen and (max-width:800px) {
    .page{
        margin-top: 25px;
    }
  }
  