@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    flex: 1;
    height: 100vh;
    @include flexStyles(flex, column, center, center);
    gap: 20px;
}

.image {
    width: 60vw;
    height: 40vh;
}

.text {
    @include textStyles("DM Sans", 20px, 600, 25px, $dark_grey);
}

.button {
    width: fit-content;
}