@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container {
    width: inherit !important;
    overflow: auto !important;
    flex: 1 !important;
}

.cellHeader {
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
    padding: 20px 5px;
    @include textStyles('Montserrat arm', 15px, 500, 15px, $dark_grey);
    background-color: $table_header;
    border-right: 1px solid $dark_grey;
    background-color: $table_header;
}

.cellBody {
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
    padding: 5px 5px 10px;
    border-right: 1px solid $dark_grey;
    background-color: $table_item;
}

.itemIcon {
    width: 70px;
    @include flexStyles(flex, row, center, center);
    text-wrap: nowrap;
    border-right: 1px solid $dark_grey;
}

.itemText {
    min-width: 200px;
    flex: 1;
}

.itemNumber {
    width: 200px;
}

.itemSelect {
    min-width: 21vw;
    flex: 1
}

.plusFooter {
    @include textStyles('Montserrat arm', 18px, 500, 18px, $buttun_darkBlue);
    @include flexStyles(flex, row, center, normal);
    gap: 15px;
    color: $buttun_darkBlue;
    width: fit-content;
    cursor: pointer;
}

.editableRow {
    cursor: pointer;
}

.editableRow:hover .cellBody {
    background-color: $avatar;
}


@media screen and (max-width:1210px) {
    .itemText {
        min-width: 120px;
        max-width: 120px;
        flex: 1;
    }
}

@media screen and (max-width:1100px) {
    .itemSelect {
        min-width: 310px;
    }
}

@media screen and (max-width:1000px) {
    .itemSelect {
        min-width: max-content;
        flex: 1
    }
}

@media screen and (max-width:800px) {
    .cellHeader {
        @include flexStyles(flex, row, center, center);
        padding: 8px 5px;
        font-size: 12px;
    }

    .cellBody {
        padding: 5px;
    }
    .itemSelect {
        min-width: 210px;
        width: 210px;
    }
    .itemText {
        min-width: 100px;
        width: 100px;
    }
    .itemIcon {
        width: 30px;
    }
    .itemNumber {
        width: 80px;
    }
    .plusFooter {
       font-size: 14px;
    }

}