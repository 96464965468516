@import '../../../../General/Styles/mixin';
@import '../../../../General/Styles/variables';

.container {
    @include flexStyles(flex, column, normal, normal);
    width: 450px;
    background-color: $white;
    padding: 20px;
    border-radius: 21px;
    margin-top: 150px;
    gap:10px;
}

.title {
    @include textStyles("Montserrat arm", 40px, 700, 54px, $text_dark);
    letter-spacing: .01em;
}

.form {
    margin-top: 30px;
    @include flexStyles(flex, column, normal, normal);
    gap: 30px;
}

.formInputs {
    @include flexStyles(flex, column, normal, normal);
    gap: 15px;
}

.signUpRow{
    width: 100%;
    @include flexStyles(flex, row, center, center);
    gap: 10px;
}

.signUpText {
    @include textStyles("Montserrat arm", 16px, 500, 18px, $text_dark);
    letter-spacing: .01em;
}

.signUp{
    @include textStyles("Montserrat arm", 16px, 600, 18px, $buttun_darkBlue);
    letter-spacing: .01em;
    cursor: pointer;
}

.signUp:hover{
   text-decoration: underline;
}

@media screen and (max-height:800px) {
    .container {
        margin-top: 50px;
    }
}

@media screen and (max-width:800px) {
    .container {
        width: 300px;
    }
    .title {
       font-size: 25px;
       line-height: 30px;
    }
    .signUpText {
        font-size: 12px;
    }
    .signUp{
       font-size: 12px;
    }
}