@import '../General/Styles/mixin';
@import '../General/Styles/variables';


.formAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
  width: 280px !important;
  max-width: 280px !important;
  background-color: $formInput;
  padding: 5px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  height: inherit !important;
  border-radius: 10px !important;
  border: 1px solid #d0d5dd;
  text-wrap: wrap !important;
  z-index: 0;
  overflow-x: auto;
}


.Mui-disabled {
  background: $white !important;
}

.formAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: $text_dark !important;
  max-width: 90% !important;
  overflow-x: auto;
}

.formAutocomplete .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.formAutocomplete .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.formAutocomplete .MuiAutocomplete-popupIndicator {
  color: $main_color !important;
}

.formAutocomplete .MuiAutocomplete-root {
  width: 100% !important;
  border-radius: 10px !important;
  margin-top: 8px !important;
}

.formAutocomplete .MuiFormControl-root .MuiInputBase-root {
  padding: 5px !important;
}

.formAutocomplete .MuiFormLabel-root {
  font-family: "Montserrat arm";
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px !important;
  color: $text_dark !important;
}

.formAutocomplete .MuiFormControl-root .MuiInputBase-root,
.formAutocomplete .MuiFormControl-root .MuiInputBase-root,
.formAutocomplete .MuiFormControl-root .MuiInputBase-root,
.formAutocomplete .MuiFormControl-root .MuiInputBase-root {
  background-color: $white !important;
}

.formAutocomplete .Mui-focused {
  border: 1px solid $main-color !important;
}

.formAutocomplete .Mui-focused.MuiAutocomplete-root {
  border: none !important;
}

.formAutocomplete .Mui-focused.MuiFormLabel-root {
  display: none !important;
}

.formAutocomplete .MuiInputLabel-shrink {
  display: none !important;
  max-width: 100% !important;
  border: 2px solid $errorColor !important;
}

.formAutocomplete .Mui-disabled {
  -webkit-text-fill-color :$text_dark !important
}

.tableAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
  min-width: 20vw !important;
  background-color: $formInput;
  padding: 5px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  height: inherit !important;
  border-radius: 10px !important;
  border: 1px solid #d0d5dd;
  flex: 1;
  z-index: 0;
}


.Mui-disabled {
  background: $white !important;
}

.tableAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: $text_dark !important;
  max-width: 90% !important;
}

.tableAutocomplete .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.tableAutocomplete .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.tableAutocomplete .MuiAutocomplete-popupIndicator {
  color: $main_color !important;
}

.tableAutocomplete .MuiAutocomplete-root {
  width: 100% !important;
  border-radius: 10px !important;
  margin-top: 8px !important;
}

.tableAutocomplete .MuiFormControl-root .MuiInputBase-root {
  padding: 5px !important;
}

.tableAutocomplete .MuiFormLabel-root {
  font-family: "Montserrat arm";
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px !important;
  color: $text_dark !important;
}

.tableAutocomplete .MuiFormControl-root .MuiInputBase-root,
.tableAutocomplete .MuiFormControl-root .MuiInputBase-root,
.tableAutocomplete .MuiFormControl-root .MuiInputBase-root,
.tableAutocomplete .MuiFormControl-root .MuiInputBase-root {
  background-color: $white !important;
}

.tableAutocomplete .Mui-focused {
  border: 1px solid $main-color !important;
}

.tableAutocomplete .MuiAutocomplete-listbox {
  width: 100% !important;
  text-wrap: wrap !important;
}


.tableAutocomplete .Mui-focused.MuiAutocomplete-root {
  border: none !important;
}

.tableAutocomplete .Mui-focused.MuiFormLabel-root {
  display: none !important;
}

.tableAutocomplete .MuiInputLabel-shrink {
  display: none !important;
  max-width: 100% !important;
  border: 2px solid $errorColor !important;
}


.customTable {
  flex: 1;
}

.customTable .MuiTableContainer-root {
  flex: 1 !important;
  overflow: visible !important;
  border: 1px solid gray !important;
  width: max-content !important;
  min-width: 100% !important;
}

.customTable .MuiTableRow-root {
  @include flexStyles(flex !important, row !important, normal !important, center !important);
  border-bottom: 1px solid gray !important;
  padding: 0 !important;
}

.customized-dialog .MuiPaper-root {
  width: 20vw !important;
  border-radius: 15px;
}

.customized-dialog .MuiDialogTitle-root {
  font-size: 20px;
  color: $buttun_darkBlue !important
}

.tableAutocompleteBig .MuiInputBase-root.MuiOutlinedInput-root {
  width: 500px !important;
}

.tableAutocompleteBig .MuiInputBase-root.MuiOutlinedInput-root {
  min-width: 20vw !important;
  background-color: $formInput;
  padding: 5px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  height: inherit !important;
  border-radius: 10px !important;
  border: 1px solid #d0d5dd;
  flex: 1;
  z-index: 0;
}

.tableAutocompleteBig .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: $text_dark !important;
  max-width: 90% !important;
}

.tableAutocompleteBig .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.tableAutocompleteBig .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.tableAutocompleteBig .MuiAutocomplete-popupIndicator {
  color: $main_color !important;
}

.tableAutocompleteBig .MuiAutocomplete-root {
  width: 100% !important;
  border-radius: 10px !important;
  margin-top: 8px !important;
}

.tableAutocompleteBig .MuiFormControl-root .MuiInputBase-root {
  padding: 5px !important;
}

.tableAutocompleteBig .MuiFormLabel-root {
  @include textStyles("Montserrat arm" !important, 14px !important, 400 !important, 15px !important, $text_dark !important);
}

.tableAutocompleteBig .MuiFormControl-root .MuiInputBase-root,
.tableAutocompleteBig .MuiFormControl-root .MuiInputBase-root,
.tableAutocompleteBig .MuiFormControl-root .MuiInputBase-root,
.tableAutocompleteBig .MuiFormControl-root .MuiInputBase-root {
  background-color: $white !important;
}

.tableAutocompleteBig .Mui-focused {
  border: 1px solid $main-color !important;
}

.tableAutocompleteBig .MuiAutocomplete-listbox {
  width: 100% !important;
  text-wrap: wrap !important;
}


.tableAutocompleteBig .Mui-focused.MuiAutocomplete-root {
  border: none !important;
}

.tableAutocompleteBig .Mui-focused.MuiFormLabel-root {
  display: none !important;
}

.tableAutocompleteBig .MuiInputLabel-shrink {
  display: none !important;
  max-width: 100% !important;
  border: 2px solid $errorColor !important;
}

.modalAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100% !important;
  max-width: 100% !important;
  background-color: $formInput;
  padding: 5px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  height: inherit !important;
  border-radius: 10px !important;
  border: 1px solid #d0d5dd;
  text-wrap: wrap !important;
  z-index: 0;
  overflow-x: auto;
}

.modalAutocomplete .MuiAutocomplete-listbox {
  text-wrap: wrap !important;
}

.modalAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: $text_dark !important;
  width: 100% !important;
  overflow-x: auto;
}

.modalAutocomplete .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.modalAutocomplete .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.modalAutocomplete .MuiAutocomplete-popupIndicator {
  color: $main_color !important;
}

.modalAutocomplete .MuiAutocomplete-root {
  width: 100% !important;
  border-radius: 10px !important;
  margin-top: 8px !important;
}

.modalAutocomplete .MuiFormControl-root .MuiInputBase-root {
  padding: 5px !important;
}

.modalAutocomplete .MuiFormLabel-root {
  font-family: "Montserrat arm";
  font-size: 14px !important;
  font-weight: 400;
  line-height: 15px !important;
  color: $text_dark !important;
}

.modalAutocomplete .MuiFormControl-root .MuiInputBase-root,
.modalAutocomplete .MuiFormControl-root .MuiInputBase-root,
.modalAutocomplete .MuiFormControl-root .MuiInputBase-root,
.modalAutocomplete .MuiFormControl-root .MuiInputBase-root {
  background-color: $white !important;
}

.modalAutocomplete .Mui-focused {
  border: 1px solid $main-color !important;
}

.modalAutocomplete .Mui-focused.MuiAutocomplete-root {
  border: none !important;
}

.modalAutocomplete .Mui-focused.MuiFormLabel-root {
  display: none !important;
}

.modalAutocomplete .MuiInputLabel-shrink {
  display: none !important;
  max-width: 100% !important;
  border: 2px solid $errorColor !important;
}

.modalAutocomplete .Mui-disabled {
  -webkit-text-fill-color :$text_dark !important
}

.modalAutocomplete .MuiPaper-root {
 border-radius: 10px !important;
}


@media screen and (max-width:1600px) {
  .customized-dialog .MuiPaper-root {
    width: 25vw !important;
  }
}

@media screen and (max-width:1430px) {
  .customized-dialog .MuiPaper-root {
    width: 30vw !important;
  }
}

@media screen and (max-width:1100px) {
  .tableAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
    min-width: 300px !important;
  }
  .tableAutocompleteBig .MuiInputBase-root.MuiOutlinedInput-root {
    width: 300px !important;
  }
}

@media screen and (max-width:800px) {
  .formAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
    width: 200px !important;
    max-width: 200px !important;
  }
  .formAutocomplete .MuiFormLabel-root {
    font-size: 10px !important;
    line-height: 10px !important;
  }
  .formAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 10px !important;
  }
  .formAutocomplete .MuiAutocomplete-listbox .MuiAutocomplete-option{
    min-height: fit-content !important;
    font-size: 12px !important;
  }
  .tableAutocomplete .MuiInputBase-root.MuiOutlinedInput-root {
    min-width: 200px !important;
  }
  .tableAutocomplete .MuiFormLabel-root {
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .tableAutocomplete .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 12px !important;
  }
  .tableAutocomplete .MuiAutocomplete-listbox .MuiAutocomplete-option{
    min-height: fit-content !important;
    font-size: 12px !important;
  }
  .tableAutocompleteBig .MuiInputBase-root.MuiOutlinedInput-root {
    width: 200px !important;
  }
  .tableAutocompleteBig .MuiFormLabel-root {
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .tableAutocompleteBig .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 12px !important;
  }
  .tableAutocompleteBig .MuiAutocomplete-listbox .MuiAutocomplete-option{
    min-height: fit-content !important;
    font-size: 12px !important;
  }
}


