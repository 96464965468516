@import '../../General/Styles/mixin';
@import '../../General/Styles/variables';

.container{
    @include flexStyles (flex,row,center,center);
    gap: 50px;
    flex-wrap: wrap;
    padding: 30px 20px;
}

@media screen and (max-width:800px) {
    .container{
        gap: 20px;
    }
}
