@import '../../../General/Styles/mixin';
@import '../../../General/Styles/variables';

.container {
    @include flexStyles(flex, column, normal, normal);
    width: 450px;
    background-color: $white;
    padding: 5px 20px 20px;
    border-radius: 21px;
    margin-top: 100px;
    gap:10px;
}

.title {
    @include textStyles("Inter", 40px, 700, 54px, $text_dark);
    letter-spacing: .01em;
}

.form {
    margin-top: 10px;
    @include flexStyles(flex, column, normal, normal);
    gap: 30px;
}

.formInputs {
    @include flexStyles(flex, column, normal, normal);
    gap: 15px;
}

@media screen and (max-height:800px) {
    .container {
        margin-top: 20px;
    }
}
